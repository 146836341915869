import { Field } from "formik";
import React from "react";

const InputField = (props) => {
  return (
    <Field
      {...props}
      className={"form-control custom-input".concat(" ", props?.className)}
    />
  );
};

export default InputField;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../components/inputs/InputField";
import CustomSelect from "../../components/select/CustomSelect";
import { DatePicker, TimePicker } from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetTripDetailsQuery,
  useUpdateTripMutation,
} from "../../app/services/trip.service";
import dayjs from "dayjs";
import { useFetchDriverCarsQuery } from "../../app/services/user.service";
import { useFetchLocationsForSelectQuery } from "../../app/services/locations.service";
import toast from "react-hot-toast";

const TripDetails = () => {
  // constants
  const timeFormat = "hh:mm a";
  const dateFormat = "DD/MM/YYYY";
  const today = dayjs();
  // fetch trip details
  const [searchParams] = useSearchParams();
  const tripId = searchParams.get("tripId");
  const { data } = useGetTripDetailsQuery(tripId);
  const tripDetails = data?.data;
  const [pickupQuery, setPickUpQuery] = useState();
  const [updateTrip] = useUpdateTripMutation();

  // fetch driver cars
  const { data: carsResponse } = useFetchDriverCarsQuery(
    tripDetails?.driverId,
    {
      skip: !tripDetails,
    }
  );
  const cars = carsResponse?.data;

  // fetch pickup locations
  const { data: pickupResponse } = useFetchLocationsForSelectQuery(
    {
      type: "pickup",
    },
    {
      skip: !tripDetails,
    }
  );
  const pickups = pickupResponse?.data;

  // fetch dropoff locations
  const { data: dropOffResponse } = useFetchLocationsForSelectQuery(
    {
      type: "dropoff",
      query: pickupQuery ?? tripDetails?.pickupSelect?.value,
    },
    {
      skip: !tripDetails,
    }
  );
  const dropoffs = dropOffResponse?.data;

  // handlers

  const handleSelectChange = (e, setFieldValue, type) => {
    setFieldValue(type, e);
  };

  const handlePickUpChange = (e, setFieldValue) => {
    setFieldValue("pickupSelect", e);
    setPickUpQuery(e.value);
  };

  const validation = (values) => {
    let errors = [];
    [
      { key: "availableSeats", fieldName: "Seats" },
      { key: "availableSpace", fieldName: "Luggage Space" },
      { key: "pricePerPassenger", fieldName: "Price/Passenger" },
      { key: "tripDate", fieldName: "Date" },
      { key: "departureTime", fieldName: "Time" },
      { key: "carSelect", fieldName: "Car" },
      { key: "pickupSelect", fieldName: "Pick-Up" },
      { key: "dropoffSelect", fieldName: "Drop-Off" },
    ]?.forEach(({ key, fieldName }) => {
      if (!values[key]) errors.push(fieldName);
    });

    return errors?.length ? errors : null;
  };

  return (
    <div className="px-4">
      <Formik
        enableReinitialize={true}
        initialValues={{
          tripId: tripDetails?._id,
          availableSeats: tripDetails?.availableSeats ?? "",
          availableSpace: tripDetails?.availableSpace ?? "",
          pricePerPassenger: tripDetails?.pricePerPassenger ?? "",
          tripDate: dayjs(tripDetails?.tripDate) ?? new Date(),
          departureTime: dayjs(tripDetails?.departureTime) ?? "",
          carSelect: tripDetails?.carSelect ?? { label: "", value: "" },
          pickupSelect: tripDetails?.pickupSelect ?? { label: "", value: "" },
          dropoffSelect: tripDetails?.dropoffSelect ?? { label: "", value: "" },
        }}
        onSubmit={(values, { setSubmitting }) => {
          const errors = validation(values);
          if (errors) {
            const errorMessage = errors?.join(", ") + " cannot be empty";
            toast.error(errorMessage);
            return;
          } else {
            updateTrip(values).then((response) => {
              toast.success(response?.data?.message);
            });
          }
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="row mt-3">
              <div className="col-md-6">
                <label>Pick-Up:</label>
                <div>
                  <CustomSelect
                    onChange={(e) => handlePickUpChange(e, setFieldValue)}
                    value={values?.pickupSelect}
                    options={pickups ?? []}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label>Drop-Off:</label>
                <CustomSelect
                  value={values?.dropoffSelect}
                  options={dropoffs ?? []}
                  onChange={(e) =>
                    handleSelectChange(e, setFieldValue, "dropoffSelect")
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-3">
                <label>Date:</label>
                <div>
                  <DatePicker
                    allowClear={false}
                    className="w-100 h40"
                    onChange={(e) => setFieldValue("tripDate", dayjs(e))}
                    value={values?.tripDate}
                    minDate={today}
                    format={dateFormat}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <label>Time:</label>
                <div>
                  <TimePicker
                    allowClear={false}
                    className="w-100 h40"
                    onChange={(e) => setFieldValue("departureTime", dayjs(e))}
                    format={timeFormat}
                    value={values?.departureTime}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label>Car:</label>
                <CustomSelect
                  onChange={(e) =>
                    handleSelectChange(e, setFieldValue, "carSelect")
                  }
                  value={values?.carSelect}
                  options={cars ?? []}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-3">
                <label>Seats</label>
                <InputField
                  type="number"
                  name="availableSeats"
                  className="w-100 h40"
                  // onChange={() => console.log("s")}
                />
              </div>
              <div className="col-sm-3">
                <label>Luggage Space:</label>
                <InputField
                  type="number"
                  name="availableSpace"
                  className="w-100 h40"
                  // onChange={() => console.log("s")}
                />
              </div>
              <div className="col-md-2">
                <label>Price/Passenger:</label>
                <InputField
                  type="number"
                  name="pricePerPassenger"
                  className="w-100 h40"
                  // onChange={() => console.log("s")}
                />
              </div>
            </div>

            <button className="route-update-btn float-end mt-3">Update</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TripDetails;

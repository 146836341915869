import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddLocation from "../components/modals/AddLocation";
import { useGetLocationsQuery } from "../app/services/locations.service";
import { MdDelete } from "react-icons/md";
import CommonModal from "../components/modals/CommonModal";
import usePageChange from "../hooks/usePageChange";

const ManageLocations = () => {
  // const { modalType } = useSelector((state) => state.headerButton);
  const { page, limit, search } = useSelector((state) => state.paginate);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    type: "",
    data: "",
  });

  // useEffect(() => {
  //   if (modalType === "addLocation") {
  //     setShow(true);
  //   }
  // }, [modalType]);

  const { data } = useGetLocationsQuery({ page, limit, search });

  const paginate = data?.data?.paginate;
  const result = data?.data?.result;

  usePageChange(paginate);

  const handleModal = (type, data) => {
    setShow(true);
    setModalData({ type, data });
  };

  return (
    <>
      <CommonModal
        show={show}
        setShow={setShow}
        type={modalData?.type}
        data={modalData?.data}
      />
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="table-head">
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Address</th>
              <th scope="col">Post Code</th>
              <th scope="col">Route No.</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {result?.length > 0 ? (
              result?.map((location, i) => {
                return (
                  <tr>
                    <th>{i + 1}</th>
                    <td className="location-name-td">{location?.name}</td>
                    <td className="address-td">{location?.address}</td>
                    <td>{location?.postCode}</td>
                    <td>{location?.routeNo}</td>
                    <td>
                      <div
                        onClick={() =>
                          handleModal("deleteLocation", location?._id)
                        }
                        className="d-flex p-1 align-items-center justify-content-center icon-box red"
                      >
                        <MdDelete className="action-icon" />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td></td>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManageLocations;

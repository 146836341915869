import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { changeSidebarVisibility } from "../../app/slices/responsiveSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="header d-flex justify-content-between">
      <div className="d-flex align-items-center side-bar-logo-div">
        <img
          className="dash-pool-logo d-none d-lg-block"
          src={require("../../assets/images/pool_logo.png")}
        />
        <h3 className="text-white my-auto d-none d-lg-block">Pool</h3>
        <button
          onClick={() => dispatch(changeSidebarVisibility())}
          className="bg-transparent border-0 d-lg-none"
          type="button"
          data-toggle="collapse"
          data-target="#sidebar"
          aria-expanded="false"
          aria-controls="sidebar"
        >
          <RxHamburgerMenu className="sidebar-toggle-btn" />
        </button>
        {/* <RxHamburgerMenu className="sidebar-toggle-btn d-block d-lg-none" /> */}
      </div>
      <div className="d-flex align-items-center mx-2">
        <img
          className="profile-img mx-1"
          src={require("../../assets/images/profile.png")}
        />

        <Dropdown>
          <Dropdown.Toggle
            className="drop-down"
            variant="success"
            id="dropdown-basic"
          >
            Admin
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>
              Logout
              <img
                className="logout-img"
                src={require("../../assets/images/logout.png")}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <p className="my-auto admin-title">Admin</p> */}
      </div>
    </div>
  );
};

export default Header;

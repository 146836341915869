import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {

  const styles = {
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 11111
    }),
  };

  return (
    <Select
      {...props}
      styles={styles}
      className="basic-single react-select"
      classNamePrefix="select"
      // filterOption={null}
      isClearable={false}
      isSearchable={true}
  
    />
  );
};

export default CustomSelect;

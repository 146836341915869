import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import ComponentFrame from "./ComponentFrame";

const Layout = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 p-0">
          <Header />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2 p-0 m-0">
          <Sidebar />
        </div>
        <div className="col-lg-10 p-0">
          <ComponentFrame>
            <Outlet />
          </ComponentFrame>
        </div>
      </div>
    </div>
  );
};

export default Layout;

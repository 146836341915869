import React from "react";
import { MdDashboard, MdPolicy, MdSupport } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { RiSteering2Fill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { BiSolidBank } from "react-icons/bi";
import { VscDebugBreakpointFunction } from "react-icons/vsc";
import { BsBookmarkCheckFill } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { TbMapRoute } from "react-icons/tb";
import { IoMegaphone } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
const Sidebar = () => {
  const { pathname } = useLocation();
  const { sidebarvisible } = useSelector((state) => state.responsive);

  const sidebarData = [
    {
      title: "Dashboard",
      icon: MdDashboard,
      path: "/dashboard",
      active: ["/dashboard"],
    },
    {
      title: "User Management",
      icon: FaUsersCog,
      path: "/users",
      active: ["/users"],
    },
    {
      title: "Driver Management",
      icon: RiSteering2Fill,
      path: "/drivers",
      active: ["/drivers", "/driver/details"],
    },
    {
      title: "Manage Locations",
      icon: IoLocationSharp,
      path: "/locations",
      active: ["/locations", "/add-location"],
    },
    {
      title: "Manage Routes",
      icon: TbMapRoute,
      path: "/routes",
      active: ["/routes", "/add-route"],
    },
    {
      title: "Bookings & Trips",
      icon: BsBookmarkCheckFill,
      path: "/bookings",
      active: ["/bookings", "/trips", "/booking/details", "/trip/details"],
    },
    {
      title: "Transactions",
      icon: GrTransaction,
      path: "/transactions",
      active: ["/transactions"],
    },
    {
      title: "Bank Transfers",
      icon: BiSolidBank,
      path: "/bank",
      active: ["/bank"],
    },
    {
      title: "Promotional Banner",
      icon: IoMegaphone,
      path: "/promotional-banner",
      active: ["/promotional-banner"],
    },
    {
      title: "Help & Support",
      icon: MdSupport,
      path: "/help-support",
      active: ["/help-support"],
    },
  ];

  const staticObj = [
    { title: "About Us", path: "/aboutus" },
    { title: "Privacy Policy", path: "/privacy" },
    { title: "Terms and Conditions", path: "/terms" },
    { title: "Insurance Agreement", path: "/insurance" },
  ];

  return (
    <div
      className={`sidebar ${
        sidebarvisible ? "show-sidebar" : "d-none d-lg-block"
      }`}
      id="sidebar"
    >
      <ul>
        {sidebarData?.map((data) => {
          return (
            <Link key={data.path} to={data.path}>
              <li
                className={`d-flex align-items-center justify-content-left sidebar-tab ${
                  data?.active.includes(pathname) && "green-bg"
                }`}
              >
                <data.icon className="sidebar-icon" />
                <span className="mx-2 my-auto">{data.title}</span>
              </li>
            </Link>
          );
        })}
        <li>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="mlm-10">
                <div className="hover-green">
                <MdPolicy className="sidebar-icon" />
                <span className="mx-2 my-auto">{"Static Content"}</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  {staticObj?.map((data) => (
                    <Link key={data?.path} to={data?.path}>
                      <li
                        className={`d-flex align-items-center justify-content-left sidebar-tab ${
                          data?.path === pathname && "green-bg"
                        }`}
                      >
                        <VscDebugBreakpointFunction className="mx-2" />
                        <span className="mx-2 my-auto">{data.title}</span>
                      </li>
                    </Link>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import toast from "react-hot-toast";

const useToast = () => {
  const makeToast = (data) => {
    if (data.status === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message || "An error occurred");
    }
  };

  return makeToast;
};

export default useToast;

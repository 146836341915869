import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import InputField from "../../components/inputs/InputField";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/select/CustomSelect";
import {
  useCreateRoutesMutation,
  useGetAllLocationsQuery,
} from "../../app/services/locations.service";

const AddRoute = () => {
  const navigate = useNavigate();
  const [createRoutes] = useCreateRoutesMutation();
  const { data } = useGetAllLocationsQuery();

  const locations = data?.data || [];

  const routeObj = {
    pickup: "",
    dropoff: "",
    fare: "",
    insurancefee: "",
    pickupselect: "",
    dropoffselect: "",
    // routeNo: "",
  };

  const validations = (values) => {
    const errors = { routes: [] };
    if (!values.routes || values.routes.length === 0) {
      toast.error("At least one route must be added");
      errors.routes = "At least one route must be added";
    } else {
      values.routes.forEach((route, index) => {
        const routeErrors = {};
        if (!route.pickup) {
          routeErrors.pickup = "(Required*)";
        }
        if (!route.dropoff) {
          routeErrors.dropoff = "(Required*)";
        }
        if (!route.fare) {
          routeErrors.fare = "(Required*)";
        }
        if (Object.keys(routeErrors).length > 0) {
          errors.routes[index] = routeErrors;
        }
      });
    }

    if (errors.routes.length === 0) {
      delete errors.routes;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ routes: [] }}
        validate={validations}
        onSubmit={(values) => {
          createRoutes(values.routes).then((response) => {
            toast.success(response.data.message);
            navigate("/routes");
          });
        }}
        render={({ values, setFieldValue }) => (
          <Form>
            <div className="d-flex justify-content-end">
              <button type="submit" className="add-location-btn">
                Submit
              </button>
            </div>
            <FieldArray
              name="routes"
              render={(arrayHelpers) => (
                <div className="mt-3">
                  {values.routes && values.routes.length > 0 ? (
                    values.routes.map((route, index) => (
                      <div key={index}>
                        <div className="row mt-2">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-4 position-relative">
                                <label className={`label-class`}>
                                  PickUp{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].pickup`}
                                  />
                                </label>
                                <CustomSelect
                                  name={`routes[${index}].pickupselect`}
                                  value={route?.pickupselect}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `routes[${index}].pickupselect`,
                                      e
                                    );
                                    setFieldValue(
                                      `routes[${index}].pickup`,
                                      e.value
                                    );
                                  }}
                                  options={locations}
                                />
                              </div>
                              <div className="col-md-4 position-relative">
                                <label className="label-class">
                                  DropOff{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].dropoff`}
                                  />
                                </label>
                                <CustomSelect
                                  // name={`routes[${index}].pickupselect`}
                                  // value={car?.pickupselect}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `routes[${index}].dropoffselect`,
                                      e
                                    );
                                    setFieldValue(
                                      `routes[${index}].dropoff`,
                                      e.value
                                    );
                                  }}
                                  options={locations}
                                />
                              </div>
                              <div className="col-md-2 position-relative">
                                <label className="label-class">
                                  Price{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].fare`}
                                  />
                                </label>
                                <InputField
                                  type="number"
                                  name={`routes[${index}].fare`}
                                />
                              </div>
                              <div className="col-md-2 position-relative">
                                <label className="label-class">
                                  Insurance Fee{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].insurancefee`}
                                  />
                                </label>
                                <InputField
                                  type="number"
                                  name={`routes[${index}].insurancefee`}
                                />
                              </div>
                              <div className="col-md-4 position-relative mt-4">
                                <label className="label-class">
                                  Recommended Price (Lower)
                                  {/* <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].insurancefee`}
                                  /> */}
                                </label>
                                <InputField
                                  type="number"
                                  name={`routes[${index}].lowerPriceRange`}
                                />
                              </div>
                              <div className="col-md-4 position-relative mt-4">
                                <label className="label-class">
                                  Recommended Price (Upper)
                                  {/* <ErrorMessage
                                    className="error-message"
                                    name={`routes[${index}].insurancefee`}
                                  /> */}
                                </label>
                                <InputField
                                  type="number"
                                  name={`routes[${index}].upperPriceRange`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex justify-content-end">
                            <button
                              className="action-btn mr-10"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </button>
                            <button
                              className="action-btn"
                              type="button"
                              Field
                              onClick={() =>
                                arrayHelpers.insert(index + 1, routeObj)
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <hr />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="add-location-btn"
                        onClick={() => arrayHelpers.push(routeObj)}
                      >
                        Add a Route
                      </button>
                    </div>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      />
    </>
  );
};

export default AddRoute;

export const modalData = {
  deleteUser: () => ({
    title: "Delete User",
    body: "Are you sure you want to delete this user?",
  }),
  deactivateUser: () => ({
    title: "Deactivate User",
    body: "Are you sure you want to deactivate this user?",
  }),
  deleteDriver: () => ({
    title: "Delete Driver",
    body: "Are you sure you want to delete this driver?",
  }),
  deactivateDriver: () => ({
    title: "Deactivate Driver",
    body: "Are you sure you want to deactivate this driver?",
  }),
  deactivateDriver: () => ({
    title: "Deactivate Driver",
    body: "Are you sure you want to deactivate this driver?",
  }),
  deleteLocation: () => ({
    title: "Deactivate Location",
    body: "Are you sure you want to delete this location?",
  }),
  deleteRoute: () => ({
    title: "Deactivate Route",
    body: "Are you sure you want to delete this route?",
  }),
  freezeUnfreezeTrip: ({ status = true }) => ({
    title: "Change trip status",
    body: `Are you sure you want to ${
      status ? "freeze" : "unfreeze"
    } this trip?`,
  }),
};

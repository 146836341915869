import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InputField from "../../components/inputs/InputField";
import CustomSelect from "../../components/select/CustomSelect";
import axios from "axios";
import { base_url } from "../../constants/envVars";
import { IoCloudUploadSharp } from "react-icons/io5";
import { useUpdateCarsMutation } from "../../app/services/user.service";
import useToast from "../../hooks/useToast";
import toast from "react-hot-toast";

const DriverCars = (props) => {
  const [updateCars] = useUpdateCarsMutation();
  const makeToast = useToast();
  const [carTypes, setCarTypes] = useState([]);
  const [carImages, setCarImages] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        base_url + "/api/v1/userApp/statics/get"
      );
      //   console.log(data);
      setCarTypes(
        data?.data?.map(({ carType, _id }) => ({ label: carType, value: _id }))
      );
    })();
  }, []);

  useEffect(() => {
    if (props) {
      setCarImages(() => props.cars?.map((car) => car?.carImages));
    }
  }, [props]);

  const formikCars = props.cars?.map((car) => ({
    _id: car?._id,
    carCategoryId: car?.carCategoryId?._id,
    carType: car?.carCategoryId?.carType,
    carSeats: car?.carSeats,
    carModel: car?.carModel,
    carColor: car?.carColor,
    carPlateNumber: car?.carPlateNumber,
    carImages: car?.carImages,
    carSelect: {
      label: car?.carCategoryId?.carType,
      value: car?.carCategoryId?._id,
    },
  }));

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ cars: formikCars || [] }}
        // validate={(values) => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid email address";
        //   }
        //   return errors;
        // }}
        onSubmit={async (values, { setSubmitting }) => {
          const formdata = new FormData();
          values?.cars?.forEach((car, index) => {
            console.log(car?.carImages);
            formdata.append(`cars[${index}][id]`, car._id);
            formdata.append(`cars[${index}][carCategoryId]`, car.carCategoryId);
            formdata.append(`cars[${index}][carModel]`, car.carModel);
            formdata.append(`cars[${index}][carSeats]`, car.carSeats);
            formdata.append(`cars[${index}][carColor]`, car.carColor);
            formdata.append(
              `cars[${index}][carPlateNumber]`,
              car.carPlateNumber
            );
            if (car?.carImages instanceof FileList) {
              Array.from(car.carImages).forEach((carImage, i) =>
                formdata.append(`cars[${index}][carImages][${i}]`, carImage)
              );
            } else if (Array.isArray(car?.carImages)) {
              car.carImages.forEach((carImage, i) =>
                formdata.append(`cars[${index}][carImages][${i}]`, carImage)
              );
            } else if (car?.carImages) {
              formdata.append(`cars[${index}][carImages][0]`, car.carImages);
            }
          });

          const response = await updateCars(formdata);
          if (response?.error) makeToast(response?.error?.data);
          if (response?.data) makeToast(response?.data);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FieldArray
              name="cars"
              render={(arrayHelpers) => (
                <div className="px-2">
                  {values.cars.map((car, i) => (
                    <div className="row" key={car._id}>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <label>Car Model</label>
                            <ErrorMessage
                              className="formik-errors"
                              name={`cars[${i}].carModel`}
                              component="span"
                            />
                          </div>
                          <div className="input-group">
                            <InputField name={`cars[${i}].carModel`} />
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <div className="d-flex justify-content-between">
                            <label>Car Seats</label>
                            <ErrorMessage
                              className="formik-errors"
                              name={`cars[${i}].carSeats`}
                              component="span"
                            />
                          </div>
                          <div className="input-group">
                            <InputField name={`cars[${i}].carSeats`} />
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <div className="d-flex justify-content-between">
                            <label>Car Color</label>
                            <ErrorMessage
                              className="formik-errors"
                              name={`cars[${i}].carColor`}
                              component="span"
                            />
                          </div>
                          <div className="input-group">
                            <InputField name={`cars[${i}].carColor`} />
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <div className="d-flex justify-content-between">
                            <label>Car Plate Number</label>
                            <ErrorMessage
                              className="formik-errors"
                              name={`cars[${i}].carPlateNumber`}
                              component="span"
                            />
                          </div>
                          <div className="input-group">
                            <InputField name={`cars[${i}].carPlateNumber`} />
                          </div>
                        </div>
                        <div className="mt-3">
                          <CustomSelect
                            name={`cars[${i}].carSelect`}
                            value={car?.carSelect}
                            onChange={(e) => {
                              setFieldValue(`cars[${i}].carSelect`, e);
                              setFieldValue(
                                `cars[${i}].carCategoryId`,
                                e.value
                              );
                            }}
                            options={carTypes}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-left">
                            {carImages?.length &&
                              carImages[i]?.map((image, ci) => {
                                return (
                                  <div
                                    key={`cars[${i}].carImages[${ci}]`}
                                    className="car-img mx-1"
                                  >
                                    <img src={image} />
                                  </div>
                                );
                              })}
                          </div>
                          <div className="wrapper">
                            <input
                              multiple
                              onChange={(e) => {
                                if (e?.target?.files) {
                                  if (e?.target?.files?.length > 3) {
                                    toast.error("Maximum 3 files allowed");
                                  } else {
                                    setFieldValue(
                                      `cars[${i}].carImages`,
                                      e.target.files
                                    );
                                    setCarImages((prev) => {
                                      const imgs = [...prev];
                                      // console.log(newImages);
                                      const newImages = Array.from(
                                        e.target.files
                                      )?.map((file) =>
                                        URL.createObjectURL(file)
                                      );
                                      imgs[i] = newImages;
                                      console.log(imgs);
                                      return imgs;
                                    });
                                  }
                                }
                              }}
                              type="file"
                              className="input-cloud"
                            />
                            <IoCloudUploadSharp className="cloud-img" />
                          </div>
                        </div>
                      </div>

                      <hr className="mt-4" />
                    </div>
                  ))}
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="update-driver-btn mb-3 w-150"
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DriverCars;

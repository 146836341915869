import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { IoCloudUploadSharp } from "react-icons/io5";
import {
  useGetPromotionalBannerQuery,
  useUpdatePromotionalBannerMutation,
} from "../app/services/promotional-banner.service";
import toast from "react-hot-toast";
import placeholderImage from "../assets/images/placeholder.jpg";
const PromotionalBanners = () => {
  const { data: response } = useGetPromotionalBannerQuery();
  const [updatePromotionalBanner] = useUpdatePromotionalBannerMutation();

  const data = response?.data;

  const [fields, setFields] = useState({ hyperlink: "" });
  const [image, setImage] = useState(placeholderImage);
  const [prevImage, setPrevImage] = useState();

  const handleChange = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setPrevImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!fields.hyperlink) return toast.error("Hyperlink cannot be empty");
    if (!image) return toast.error("Please upload a banner");

    const formdata = new FormData();
    ["hyperlink"]?.forEach((key) => formdata.append(key, fields[key]));

    formdata.append("image", image);

    updatePromotionalBanner(formdata).then(({ data }) =>
      toast.success(data.message)
    );
  };

  useEffect(() => {
    if (data) {
      setImage(data.image);
      setFields(() => ({ hyperlink: data.hyperlink }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (prevImage !== placeholderImage) {
        URL.revokeObjectURL(prevImage);
      }
    };
  }, [prevImage]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="promotional-image-wrapper">
          <Image
            className="promotional-banner-image"
            src={prevImage ?? image}
            rounded
          />
          <div className="promotional-input-div">
            <input
              onChange={handleImageChange}
              type="file"
              className="promotional-img-input"
            />
            <IoCloudUploadSharp className="promotional-upload-icon" />
          </div>
        </div>

        <label className="mt-3">Hyperlink</label>
        <input
          onChange={handleChange}
          name="hyperlink"
          className="form-control custom-input"
          placeholder="Enter Hyperlink"
          value={fields?.hyperlink}
        />

        <button className="promo-submit-btn mt-3" type="submit">
          Submit
        </button>
      </form>
    </>
  );
};

export default PromotionalBanners;

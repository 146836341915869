import "./App.css";
import Loader from "./components/loader/Loader";
import PageRoutes from "./routes/PageRoutes";

function App() {
  return (
    <>
      <Loader />
      <PageRoutes />
    </>
  );
}

export default App;

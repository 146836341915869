import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRange from "./DateRange";
import ExportModal from "../../components/modals/ExportModal";
import dayjs from "dayjs";
import { api_url } from "../../constants/envVars";
import axios from "axios";

const AccountDetailsCard = ({ data, dateRange, setDateRange }) => {
  const today = dayjs();
  const aWeekBack = dayjs().subtract(7, "days");

  const [showExportDate, setShowExportDate] = useState(false);
  const [exportDateRange, setExportDateRange] = useState([aWeekBack, today]);
  const handleExport = () => setShowExportDate(true);

  const exportRequest = (account, from, to) => {
    axios
      .get(
        `${api_url}/export/transactions?account=${account}&from=${from}&to=${to}`,
        {
          headers: { Authorization: localStorage.getItem("token") },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions.xlsx`);
        document.body.appendChild(link);
        link.click();
        setShowExportDate(false);
      });
  };

  useEffect(() => {
    if (showExportDate) {
      exportRequest(
        data?.account,
        exportDateRange[0].format("YYYYMMDD"),
        exportDateRange[1].format("YYYYMMDD")
      );
    }
  }, [exportDateRange]);

  return (
    <div className="row custom-card mx-3">
      <div className="row">
        <div className="col-md-9 d-flex justify-content-between">
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">Account No:</p>{" "}
              <p>{data?.account}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">Balance:</p>
              <p>{data?.accountBalance?.availableBalance}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">From:</p>
              <p>{data?.beginBalance}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">To:</p>
              <p>{data?.endBalance}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 d-flex justify-content-between">
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">Total Transactions:</p>{" "}
              <p>{data?.total?.count}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">Credited:</p>{" "}
              <p>+{data?.total?.credit}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              <p className="label-details">Debited:</p>{" "}
              <p>-{data?.total?.debit}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex">
              {showExportDate ? (
                <p>Export date range:</p>
              ) : (
                <button onClick={handleExport} className="export-btn">
                  Export
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          {showExportDate && (
            <DateRange
              dateRange={exportDateRange}
              setDateRange={setExportDateRange}
            />
          )}
        </div>
      </div>

      {/* <ExportModal show={exportShow} setShow={setExportShow} /> */}
      {/* <div className="col-md-3">
        <div className="d-flex">
          <p className="label-details">Credited:</p>{" "}
          <p>{data?.total?.credit}</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="d-flex">
          <p className="label-details">Debited:</p> <p>{data?.total?.debit}</p>
        </div>
      </div> */}
    </div>
  );
};

export default AccountDetailsCard;

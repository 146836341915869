import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { ImUserMinus } from "react-icons/im";
import CommonModal from "../components/modals/CommonModal";
import {
  useChangeVerificationStatusMutation,
  useGetDriversQuery,
} from "../app/services/user.service";
import { Badge, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  verficationActions,
  verficationTitles,
  verificationVariants,
} from "../constants/constantData";
import useToast from "../hooks/useToast";
import usePageChange from "../hooks/usePageChange";

const DriverManagement = () => {
  const makeToast = useToast();
  const [changeVerificationStatus] = useChangeVerificationStatusMutation();
  const { page, limit, search } = useSelector((state) => state.paginate);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    type: "",
    data: "",
  });

  const { data } = useGetDriversQuery({ page, limit, search });

  const drivers = data?.data?.result;
  const paginate = data?.data?.paginate;

  usePageChange(paginate);

  const handleModal = (type, data) => {
    setShow(true);
    setModalData({ type, data });
  };

  const handleVerificationStatus = async (type, id) => {
    const { data } = await changeVerificationStatus({ type, id });
    makeToast(data);
  };

  return (
    <>
      <CommonModal
        show={show}
        setShow={setShow}
        type={modalData.type}
        data={modalData.data}
      />

      <div className="table-responsive h496">
        <table className="table table-striped">
          <thead>
            <tr className="table-head">
              <th scope="col">#</th>
              <th scope="col">Full Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Status</th>
              <th scope="col">Verification</th>
              <th scope="col align-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {drivers?.length ? (
              drivers?.map((driver, i) => {
                return (
                  <tr key={i} className="align-middle">
                    <th scope="row">{i + 1}</th>
                    <td>{driver?.firstName?.concat(" ", driver?.lastName)}</td>
                    <td>{driver?.email}</td>
                    <td>{driver?.phone}</td>
                    <td>
                      <Badge
                        className="h-50"
                        bg={driver?.isDeactivated ? "danger" : "success"}
                      >
                        {driver?.isDeactivated ? "Deactivated" : "Active"}
                      </Badge>
                    </td>
                    <td>
                      <DropdownButton
                        onSelect={(e) =>
                          handleVerificationStatus(e, driver?._id)
                        }
                        as={ButtonGroup}
                        key={verificationVariants[driver?.isAdminVerified]}
                        // id={`dropdown-variants-${variant}`}
                        variant={verificationVariants[driver?.isAdminVerified]}
                        title={verficationTitles[driver?.isAdminVerified]}
                      >
                        {Object.keys(verficationActions)
                          ?.filter(
                            (key) =>
                              verficationActions[key] !==
                              verficationActions[driver?.isAdminVerified]
                          )
                          ?.map((key) => (
                            <Dropdown.Item eventKey={key}>
                              {verficationActions[key]}
                            </Dropdown.Item>
                          ))}
                      </DropdownButton>
                    </td>
                    <td>
                      <div className="d-flex ">
                        <Link to={`/driver/details?id=${driver?._id}`}>
                          <div className="d-flex p-1 align-items-center justify-content-center icon-box blue">
                            <FaEye className="action-icon" />
                          </div>
                        </Link>

                        <div
                          onClick={() =>
                            handleModal("deactivateDriver", driver)
                          }
                          className="d-flex p-1 align-items-center justify-content-center icon-box yellow"
                        >
                          <ImUserMinus className="action-icon" />
                        </div>
                        <div
                          onClick={() =>
                            handleModal("deleteDriver", driver?._id)
                          }
                          className="d-flex p-1 align-items-center justify-content-center icon-box red"
                        >
                          <MdDelete className="action-icon" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td>No entries yet</td>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DriverManagement;

import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BookingsTable from "../subpages/bookings/BookingsTable";
import TripsTable from "../subpages/trips/TripsTable";

const BookingsAndTrips = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab = pathname.split("/")[1];

  const handleTabSelect = (k) => {
    navigate(`/${k}`);
  };

  const TableHead = ({ children }) => {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="table-head">
              <th scope="col">#</th>
              <th scope="col">Trip Id</th>
              <th scope="col">Date</th>
              <th scope="col">Driver Phone</th>
              <th scope="col">Route</th>
              {currentTab === "bookings" ? (
                <th scope="col">Passenger Phone</th>
              ) : null}
              <th scope="col">Passengers</th>
              <th scope="col">Amount</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    );
  };

  const tabs = [
    { key: "bookings", title: "All Bookings" },
    { key: "trips", title: "All Trips" },
    // { key: "upcoming", title: "Upcoming" },
    // { key: "ongoing", title: "On-Going" },
    // { key: "completed", title: "Completed" },
  ];
  const componentObj = {
    bookings: <BookingsTable />,
    trips: <TripsTable />,
  };

  return (
    <>
      <Tabs
        id="fill-tab-example"
        fill
        activeKey={currentTab}
        onSelect={handleTabSelect}
        className="mb-3 driver-details-tab"
      >
        {tabs?.map((tab, i) => (
          <Tab key={i} eventKey={tab.key} title={tab.title}></Tab>
        ))}
      </Tabs>
      <TableHead>{componentObj[currentTab]}</TableHead>
    </>
  );
};

export default BookingsAndTrips;

import React, { useEffect, useState } from "react";
import Editor from "../components/editor/Editor";
import { useLocation } from "react-router-dom";
import {
  useGetStaticContentQuery,
  useUpdateContentMutation,
} from "../app/services/static.service";
import toast from "react-hot-toast";

const StaticContent = () => {
  const { pathname } = useLocation();
  const [value, setValue] = useState("");
  const trimmedPath = pathname.slice(1);

  const { data } = useGetStaticContentQuery(trimmedPath);
  const [updateContent] = useUpdateContentMutation();

  const handleUpdate = () => {
    updateContent({ contentType: trimmedPath, content: value })
      .unwrap()
      .then((payload) => {
        if (payload?.status === 200) {
          toast.success(payload.message);
        }
      })
      .catch((error) => {
        const errorMessage = error?.data?.message || "An error occurred";
        toast.error(errorMessage);
      });
  };

  useEffect(() => {
    if (data?.data) {
      if (data?.data?.contentType === trimmedPath) {
        setValue(data.data.content);
      }
    } else {
      setValue("");
    }
  }, [data]);

  return (
    <>
      <Editor value={value} setValue={setValue} />
      <div className="d-flex justify-content-center mt-3">
        <button onClick={handleUpdate} className="update-btn">
          Update
        </button>
      </div>
    </>
  );
};

export default StaticContent;

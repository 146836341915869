import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import InputField from "../../components/inputs/InputField";
import toast from "react-hot-toast";
import { useAddLocationsMutation } from "../../app/services/locations.service";
import { useNavigate } from "react-router-dom";

const AddLocation = () => {
  const [addLocation] = useAddLocationsMutation();
  const navigate = useNavigate();
  const locationObj = {
    name: "",
    address: "",
    postCode: "",
    routeNo: "",
  };

  const validations = (values) => {
    const errors = { locations: [] };

    if (!values.locations || values.locations.length === 0) {
      toast.error("At least one location must be added");
      errors.locations = "At least one location must be added";
    } else {
      values.locations.forEach((location, index) => {
        const locationErrors = {};
        if (!location.name) {
          locationErrors.name = "(Required*)";
        }
        if (!location.postCode) {
          locationErrors.postCode = "(Required*)";
        }
        if (!location.routeNo) {
          locationErrors.routeNo = "(Required*)";
        }
        if (!location.address) {
          locationErrors.address = "(Required*)";
        }
        if (Object.keys(locationErrors).length > 0) {
          errors.locations[index] = locationErrors;
        }
      });
    }

    if (errors.locations.length === 0) {
      delete errors.locations;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ locations: [] }}
        validate={validations}
        onSubmit={(values) => {
          addLocation(values.locations).then((response) => {
            toast.success(response.data.message);
            navigate("/locations");
          });
        }}
        render={({ values }) => (
          <Form>
            <div className="d-flex justify-content-end">
              <button type="submit" className="add-location-btn">
                Submit
              </button>
            </div>
            <FieldArray
              name="locations"
              render={(arrayHelpers) => (
                <div className="mt-3">
                  {values.locations && values.locations.length > 0 ? (
                    values.locations.map((location, index) => (
                      <div key={index}>
                        <div className="row mt-2">
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-md-4 position-relative">
                                <label className={`label-class`}>
                                  Name{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`locations[${index}].name`}
                                  />
                                </label>
                                <InputField name={`locations[${index}].name`} />
                              </div>
                              <div className="col-md-4 d-flex position-relative">
                                <label className="label-class">
                                  Post Code{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`locations[${index}].postCode`}
                                  />
                                </label>
                                <InputField
                                  name={`locations[${index}].postCode`}
                                />
                              </div>
                              <div className="col-md-4 position-relative">
                                <label className="label-class">
                                  Route No{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`locations[${index}].routeNo`}
                                  />
                                </label>
                                <InputField
                                  name={`locations[${index}].routeNo`}
                                />
                              </div>
                              <div className="col-md-8 position-relative mt-4">
                                <label className="label-class">
                                  Address{" "}
                                  <ErrorMessage
                                    className="error-message"
                                    name={`locations[${index}].address`}
                                  />
                                </label>
                                <InputField
                                  name={`locations[${index}].address`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex justify-content-end">
                            <button
                              className="action-btn mr-10"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </button>
                            <button
                              className="action-btn"
                              type="button"
                              Field
                              onClick={() =>
                                arrayHelpers.insert(index + 1, locationObj)
                              } // insert an empty string at a position
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <hr />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="add-location-btn"
                        onClick={() => arrayHelpers.push(locationObj)}
                      >
                        Add a Location
                      </button>
                    </div>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      />
    </>
  );
};

export default AddLocation;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_url } from "../../constants/envVars";

export const promotionalApi = createApi({
  reducerPath: "promoional",
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Promotional"],
  endpoints: (builder) => ({
    getPromotionalBanner: builder.query({
      query: () => ({
        url: `/promotional-banner`,
      }),
      providesTags: ["Promotional"],
    }),
    updatePromotionalBanner: builder.mutation({
      query: (data) => ({
        url: "/promotional-banner",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Promotional"],
    }),
  }),
});

export const {
  useGetPromotionalBannerQuery,
  useUpdatePromotionalBannerMutation,
} = promotionalApi;

import { ErrorMessage, Formik, Form } from "formik";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputField from "../inputs/InputField";
import { useUpdateRouteMutation } from "../../app/services/locations.service";
import useToast from "../../hooks/useToast";

const RouteModal = ({ show, setShow, data }) => {
  const makeToast = useToast();
  const handleClose = () => setShow(false);
  const [updateRoute] = useUpdateRouteMutation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Route Details
          </Modal.Title>
        </Modal.Header>

        <div>
          <Formik
            enableReinitialize
            initialValues={{
              routeId: data?._id,
              fare: data?.fare ?? "",
              insurancefee: data?.insurancefee ?? 0.0,
              lowerPriceRange: data?.lowerPriceRange ?? 0.0,
              upperPriceRange: data?.upperPriceRange ?? 0.0,
            }}
            validate={(values) => {
              const errors = {};
              return errors;
            }}
            onSubmit={(values, { resetForm }) => {
              updateRoute(values).then((response) => {
                makeToast(response.data);
                handleClose();
              });
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <label>Price</label>
                          <ErrorMessage
                            className="formik-errors"
                            name="adminEmail"
                            component="span"
                          />
                        </div>
                        <InputField
                          placeholder="Fare"
                          type="number"
                          name="fare"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <label>Insurance Fee</label>
                          <ErrorMessage
                            className="formik-errors"
                            name="adminEmail"
                            component="span"
                          />
                        </div>
                        <InputField
                          placeholder="Insurance Fee"
                          type="number"
                          name="insurancefee"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <label>Recommended Price(Lower)</label>
                          {/* <ErrorMessage
                            className="formik-errors"
                            name="adminEmail"
                            component="span"
                          /> */}
                        </div>
                        <InputField
                          placeholder="Lower Range"
                          type="number"
                          name="lowerPriceRange"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-between">
                          <label>Recommended Price(Upper)</label>
                          {/* <ErrorMessage
                            className="formik-errors"
                            name="adminEmail"
                            component="span"
                          /> */}
                        </div>
                        <InputField
                          placeholder="Upper Range"
                          type="number"
                          name="upperPriceRange"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button variant="primary" className="yes-btn" type="submit">
                    Update
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </div>

        {/* <Modal.Body>{"modalData[type]?.body"}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            className="yes-btn"
            onClick={handleAffirmation}
          >
            Update
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default RouteModal;

import dayjs from "dayjs";
import React from "react";

const TransactionsTable = ({ transactions }) => {
  return (
    <div className="table-responsive tableFixHead">
      <table className="table table-striped">
        <thead>
          <tr className="table-head">
            <th scope="col">#</th>
            <th scope="col">Code</th>
            <th scope="col">Record</th>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Amount</th>
            <th scope="col">Balance</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.length ? (
            transactions?.map((trans, i) => {
              return (
                <tr key={i} className="align-middle">
                  <th scope="row">{i + 1}</th>
                  <td>{"#" + trans?.code}</td>
                  <td>{"#" + trans?.record}</td>
                  <td>{dayjs(trans?.tranDate).format("DD MMM YYYY")}</td>
                  <td>
                    {dayjs(trans?.time.slice(0, -4), "hhmm").format("hh:mm a")}
                  </td>
                  <td>
                    <div
                      className={
                        String(trans?.amount)?.startsWith("-")
                          ? "color-red"
                          : "color-green"
                      }
                    >
                      {trans?.amount}
                    </div>
                  </td>
                  <td>{trans?.balance}</td>
                </tr>
              );
            })
          ) : (
            <td>No entries yet</td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsTable;

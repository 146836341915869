import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_url } from "../../constants/envVars";

export const staticApi = createApi({
  reducerPath: "static",
  baseQuery: fetchBaseQuery({
    baseUrl: api_url,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Content"],
  endpoints: (builder) => ({
    getStaticContent: builder.query({
      query: (contentType) => ({
        url: `/static/${contentType}`,
      }),
      providesTags: ["Content"],
    }),
    updateContent: builder.mutation({
      query: (data) => ({
        url: "/static/content",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Content"],
    }),
  }),
});

export const { useGetStaticContentQuery, useUpdateContentMutation } = staticApi;

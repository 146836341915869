import {
  useDeactivateDriverMutation,
  useDeactivateUserMutation,
  useDeleteDriverMutation,
  useDeleteUserMutation,
} from "../app/services/user.service";
import useToast from "./useToast";
import {
  useDeleteLocationMutation,
  useDeleteRouteMutation,
} from "../app/services/locations.service";
import { useChangeTripStatusMutation } from "../app/services/trip.service";

export const useModalFunctions = () => {
  const [deactivateUser] = useDeactivateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deactivateDriver] = useDeactivateDriverMutation();
  const [deleteDriver] = useDeleteDriverMutation();
  const [deleteLocation] = useDeleteLocationMutation();
  const [deleteRoute] = useDeleteRouteMutation();
  const [changeTripStatus] = useChangeTripStatusMutation();
  const makeToast = useToast();
  const handleModalAction = async (type, data) => {
    switch (type) {
      case "deactivateUser":
        try {
          const response = await deactivateUser({
            type: data.isDeactivated ? "activate" : "deactivate",
            id: data?._id,
          });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to deactivate user: ", error);
        }
        break;

      case "deleteUser":
        try {
          const response = await deleteUser({ id: data });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to delete user: ", error);
        }
        break;
      case "deactivateDriver":
        try {
          const response = await deactivateDriver({
            type: data.isDeactivated ? "activate" : "deactivate",
            id: data?._id,
          });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to deactivate driver: ", error);
        }
        break;

      case "deleteDriver":
        try {
          const response = await deleteDriver({ id: data });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to delete driver: ", error);
        }
        break;

      case "deleteLocation":
        try {
          const response = await deleteLocation({ id: data });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to delete Location: ", error);
        }
        break;
      case "deleteRoute":
        try {
          const response = await deleteRoute({ id: data });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to delete Route: ", error);
        }
        break;
      case "freezeUnfreezeTrip":
        try {
          const { _id: id, isActive } = data;
          const type = isActive === true ? "freeze" : "unfreeze";
          const response = await changeTripStatus({ type, id });
          makeToast(response.data);
        } catch (error) {
          console.error("Failed to delete Route: ", error);
        }
        break;

      default:
        console.warn(`Unhandled action type: ${type}`);
        break;
    }
  };

  return handleModalAction;
};

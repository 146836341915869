import React from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";

const Loader = () => {
  const { loading } = useSelector((state) => state.loader);

  const styles = {
    // position: "absolute",
    // zIndex: 99999,
  };

  return (
    <>
      {loading && (
        <div className="loader-div d-flex align-items-center justify-content-center">
          <FadeLoader cssOverride={styles} color="#2ad82a" loading={true} />
        </div>
      )}
    </>
  );
};

export default Loader;

import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "./services/auth.service";
import { userApi } from "./services/user.service";
import paginationSlice from "./slices/pagination.slice";
import { staticApi } from "./services/static.service";
import { dashboardApi } from "./services/dashboard.service";
import { bookingApi } from "./services/booking.service";
import { supportApi } from "./services/support.service";
import loadingMiddleware from "./middleware/loaderMiddleware";
import loaderSlice from "./slices/loaderSlice";
import { transactionApi } from "./services/transaction.service";
import headerButtonSlice from "./slices/headerButtonSlice";
import { locationApi } from "./services/locations.service";
import responsiveSlice from "./slices/responsiveSlice";
import apiMiddleware from "./middleware/apiMiddleware";
import { tripApi } from "./services/trip.service";
import { promotionalApi } from "./services/promotional-banner.service";

export const store = configureStore({
  reducer: {
    paginate: paginationSlice,
    loader: loaderSlice,
    headerButton: headerButtonSlice,
    responsive: responsiveSlice,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [staticApi.reducerPath]: staticApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [tripApi.reducerPath]: tripApi.reducer,
    [promotionalApi.reducerPath]: promotionalApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      userApi.middleware,
      staticApi.middleware,
      dashboardApi.middleware,
      supportApi.middleware,
      bookingApi.middleware,
      transactionApi.middleware,
      locationApi.middleware,
      tripApi.middleware,
      promotionalApi.middleware,
      apiMiddleware,
      loadingMiddleware
    ),
});

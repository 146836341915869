export const verficationTitles = {
  pending: "Pending",
  verified: "Verified",
  rejected: "Rejected",
};

export const verficationActions = {
  pending: "Pending",
  verified: "Verify",
  rejected: "Reject",
};

export const verificationVariants = {
  pending: "warning",
  verified: "success",
  rejected: "danger",
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  limit: 8,
  search: "",
  totalPages: 0,
  totalResults: 0,
  //   paginate: {
  //     page: 1,
  //     limit: 8,
  //     totalPages: 1,
  //     totalResults: 1,
  //   },
};

const paginationSlice = createSlice({
  name: "paginate",
  initialState,
  reducers: {
    setDefaults: (state) => {
      state.page = 1;
      state.limit = 8;
      state.search = "";
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    setTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
    setTotalResults: (state, { payload }) => {
      state.totalResults = payload;
    },
  },
});

export const {
  setPage,
  setLimit,
  setSearch,
  setTotalPages,
  setTotalResults,
  setDefaults,
} = paginationSlice.actions;

export default paginationSlice.reducer;

import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { Form, Formik } from "formik";
import InputField from "../inputs/InputField";
import { useSupportReplyMutation } from "../../app/services/support.service";
import useToast from "../../hooks/useToast";

const SupportReply = ({ show, setShow, data }) => {
  const [supportReply] = useSupportReplyMutation();
  const handleClose = () => setShow(false);
  const makeToast = useToast();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reply</Modal.Title>
      </Modal.Header>

      <div>
        <Formik
          enableReinitialize
          initialValues={{
            supportId: data?._id,
            tripId: data?.tripId?.tripNo,
            name: data?.userId?.firstName.concat(" ", data?.userId?.lastName),
            email: data?.userId?.email,
            text: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.text) {
              errors.text = "Required*";
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            setTimeout(() => {
              supportReply(values).then((response) => {
                makeToast(response.data);
                handleClose();
              });
            }, 400);
            resetForm();
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Modal.Body>
                <InputField type="email" name="email" disabled />
                <InputField
                  type="text"
                  name="text"
                  as="textarea"
                  className="mt-3"
                  rows="8"
                  placeholder={
                    errors.text
                      ? errors.text
                      : `Description: ${data?.description || ""}`
                  }
                />
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <div className="d-flex justify-content-between w-150">
                  <Button
                    variant="primary"
                    className="yes-btn w70"
                    type="submit"
                  >
                    Send
                  </Button>
                  <Button
                    variant="secondary"
                    className="w70"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default SupportReply;

import React, { useState } from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const DateRange = ({ dateRange, setDateRange }) => {
  const today = dayjs();
  return (
    <div>
      <RangePicker
        allowClear={false}
        format="DD MMM YYYY"
        maxDate={today}
        onChange={(e) => setDateRange(e)}
        defaultValue={dateRange}
        value={dateRange}
      />
    </div>
  );
};

export default DateRange;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRoutesQuery } from "../app/services/locations.service";
import usePageChange from "../hooks/usePageChange";
import CommonModal from "../components/modals/CommonModal";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import RouteModal from "../components/modals/RouteModal";

const ManageRoutes = () => {
  const { modalType } = useSelector((state) => state.headerButton);
  const [show, setShow] = useState(false);
  const [routeModalShow, setRouteModalShow] = useState(false);
  const { page, limit, search } = useSelector((state) => state.paginate);
  const [modalData, setModalData] = useState({
    type: "",
    data: "",
  });

  const { data } = useGetRoutesQuery({ page, limit, search });

  const paginate = data?.data?.paginate;
  const routes = data?.data?.result;

  usePageChange(paginate);

  const handleModal = (type, data) => {
    setShow(true);
    setModalData({ type, data });
  };

  const handleUpdateRoute = (data) => {
    setRouteModalShow(true);
    setModalData({ data });
  };

  return (
    <>
      <CommonModal
        show={show}
        setShow={setShow}
        type={modalData?.type}
        data={modalData?.data}
      />
      <RouteModal
        show={routeModalShow}
        setShow={setRouteModalShow}
        data={modalData?.data}
      />
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="table-head">
              <th scope="col">#</th>
              <th scope="col">Pick-Up</th>
              <th scope="col">Drop-Off</th>
              <th scope="col">Price</th>
              <th scope="col">Insurance Fee</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {routes?.length > 0 ? (
              routes?.map((route, i) => (
                <tr>
                  <th>{i + 1}</th>
                  <td>{route?.pickup?.name}</td>
                  <td>{route?.dropoff?.name}</td>
                  <td>{route?.fare}₮</td>
                  <td>{route?.insurancefee ?? "0.00"}₮</td>
                  <td>
                    <div className="d-flex">
                      <div
                        onClick={() => handleUpdateRoute(route)}
                        className="d-flex p-1 align-items-center justify-content-center icon-box blue"
                      >
                        <MdEdit className="action-icon" />
                      </div>
                      <div
                        onClick={() => handleModal("deleteRoute", route?._id)}
                        className="d-flex p-1 align-items-center justify-content-center icon-box red"
                      >
                        <MdDelete className="action-icon" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <td></td>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManageRoutes;
